import * as React from "react"
import Header from "../components/header"
import Footer from "../components/footer"

const PrivacyPolicy = () => (
  <div>
    <Header useLinks={true} />
    <section className="privacy-policy">
      <div className="container">
        <h1>Techscoped Privacy Policy </h1>
        <p>
          At Techscoped, we are committed to protecting the privacy and security
          of your personal information. This Privacy Policy outlines how we
          collect, use, and disclose information when you interact with our
          website. By using our website, you consent to the practices described
          in this Privacy Policy.{" "}
        </p>
        <ol>
          <li>
            Information We Collect
            <ol>
              <li>
                Personal Information: You can browse through most of our website
                without giving any personal data. We collect information from
                you when you submit it to us by email or by other means (for
                example, by contacting us through our “LinkedIn” page or
                telephone). Please do not share with us any other person’s
                personal information, any categories of your own personal
                information that have not been requested by the Company, or any
                confidential, proprietary, secret or legally protected
                information which you are under any legal obligation to not
                provide to or share with the Company.
              </li>
              <li>
                Usage Information: We automatically collect certain information
                about your use of our website. This may include your IP address,
                device information, browser type, operating system, and other
                technical data. We may also collect information about your
                interactions with our website, such as the pages you visit and
                the actions you take.
              </li>
            </ol>
          </li>
          <li>
            Use of Information
            <ol>
              <li>
                Provision of Services: We use the information we collect to
                provide, maintain, and improve our website and offering. This
                includes analyzing user behavior and preferences, personalizing
                your experience, and optimizing our website's performance.
              </li>
              <li>
                Communication: We may use your contact information to
                communicate with you about our services or respond to your
                inquiry.{" "}
              </li>
              <li>
                Aggregated Data: We may aggregate and anonymize the information
                we collect to generate statistical and analytical insights.
                These insights may be used for research, reporting, or marketing
                purposes, but will not include any personally identifiable
                information without your consent.
              </li>
              <li>
                Legal Compliance: We may use and disclose your information to
                comply with applicable laws, regulations, legal processes, or
                enforceable governmental requests, or to protect our rights,
                privacy, safety, or property.
              </li>
            </ol>
          </li>
          <li>
            Data Sharing and Disclosure
            <ol>
              <li>
                Service Providers: We do not share the personal data you have
                shared with us with any third-party without your prior consent.{" "}
              </li>
              <li>
                Business Transfers: In the event of a merger, acquisition, or
                sale of all or a portion of our assets, your information may be
                transferred as part of the transaction. We will notify you via
                email or a prominent notice on our website before your
                information becomes subject to a different privacy policy.
              </li>
            </ol>
          </li>
          <li>
            Data Security
            <br />
            We implement appropriate technical and organizational measures to
            protect your personal information from unauthorized access,
            disclosure, alteration, or destruction.
          </li>
          <li>
            Third-Party Links
            <br />
            This Policy does not apply to other websites to which we link to,
            and other websites may not treat information collected online in the
            same manner as we do. We are not responsible for the privacy
            practices or content of these third parties. We encourage you to
            review the privacy policies of those websites or services before
            providing any personal information.
          </li>
          <li>
            Children’s Privacy
            <br />
            Our Services are not directed to individuals under the age of 13. We
            do not knowingly collect personal information from children. If you
            are a parent or guardian and believe that your child has provided us
            with personal information, please contact us, and we will take steps
            to remove that information.
          </li>
          <li>
            GDPR Compliance
            <br />
            If you are a resident of the European Economic Area (EEA), the GDPR
            provides you with certain rights regarding your personal
            information:
            <p>
              Right of Access: You have the right to request access to your
              personal information and receive a copy of it.
            </p>
            <p>
              Right to Rectification: You have the right to request the
              correction of any inaccurate personal information we hold about
              you.
            </p>
            <p>
              Right to Erasure: You have the right to request the deletion of
              your personal information, subject to certain exceptions.
            </p>
            <p>
              Right to Restriction of Processing: You have the right to request
              the restriction of processing your personal information in certain
              circumstances.
            </p>
            <p>
              Right to Data Portability: You have the right to receive a
              structured, commonly used, and machine-readable format of your
              personal information and transmit it to another data controller.
            </p>
            <p>
              Right to Object: You have the right to object to the processing of
              your personal information in certain circumstances.
            </p>
            <p>
              Right to Withdraw Consent: If we rely on your consent to process
              your personal
            </p>
            <p>
              To exercise your GDPR rights or if you have any questions or
              concerns regarding your personal information, please contact us at
              hello@techscoped.com
            </p>
          </li>
          <li>
            CCPA Compliance
            <br />
            If you are a California resident, the CCPA provides you with certain
            rights regarding your personal information:
            <p>
              Right to Know: You have the right to request information about the
              categories of personal information we have collected, disclosed,
              and sold about you, as well as the sources from which the
              information was collected.{" "}
            </p>
            <p>
              Right to Delete: You have the right to request the deletion of
              your personal information, subject to certain exceptions.
            </p>
            <p>
              Right to Opt-Out: You have the right to opt-out of the sale of
              your personal information.
            </p>
            <p>
              Right to Non-Discrimination: We will not discriminate against you
              for exercising your CCPA rights.
            </p>
            <p>
              To exercise your CCPA rights or if you have any questions or
              concerns regarding your personal information, please contact us at
              hello@techscoped.com
            </p>
          </li>
          <li>
            Your Rights
            <br /> You may have certain rights concerning your personal
            information, including the right to access, correct, or delete your
            information. If you have any questions or wish to exercise these
            rights, please contact us at hello@techscoped.com.
          </li>
          <li>
            Changes to this Privacy Policy
            <br />
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or applicable laws.
          </li>
        </ol>
      </div>
    </section>
    <Footer useLinks={true} />
  </div>
)

export default PrivacyPolicy
